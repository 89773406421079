.mainServiceContainer {
  background-color: rgb(255, 255, 255);
  /* height: 523px;
  display: flex;
  padding-left:65px; */
  display: flex;
  align-items: center;
  padding: 100px 100px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.desContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.desHeading {
  font-size: 48px;
  font-weight: 700;
}
.servicesDes{
  font-size: 24px;
  font-weight: 400;
  color: #7A7A7A;
}

.servicesIcon {
  color: #195776;
  width: 60px;
  height: 60px;
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 100%;
}
.iconContainer {
  display: flex;
  /* padding: 30px; */
  flex-direction: column;
  /* padding-left: 89px; */
  align-items: center;
  width: auto;

}

.subIconContainer {
  padding: 20px;
  background-color: rgb(255, 255, 255);
  /* margin-right: 50px; */
  border-radius: 10px;
  width: 263px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.4s;
}


.subIconContainer:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transform: translateY(-4px);
}

.iconBackgroundContainer {
  background-color: #195776;
  /* max-width: 500px; */
  height: 176px;
  width: 100%;
  /* padding-left: 300px; */
  border-radius: 10px;
}

.mainIconContainer {
  display: flex;
  margin-top: -140px;
  gap: 20px;
  /* padding: 0px 70px; */
}

.iconText{
  font-weight: 700;
  font-size: 20px;
  color: #195776;
  padding-top: 5px;
  width: 100%;
  min-width: 172px;
  text-align: center;
}
.iconTextdes{
  font-weight: 500;
  font-size: 14px;
  color: #7A7A7A;
  width: 100%;
  min-width: 172px;
  text-align: justify;
}
.iconTextdes span{

  color: #195776;
font-weight: bold;
}
.service-pdf-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 20px 0px;
}
.service-pdf-container a{
  font-size: 16px;
  color: #195776;;
  cursor: pointer;}





/* Smartphones */
@media only screen and (max-width: 767px) {

  .mainServiceContainer {
    padding: 100px 20px;
}
.mainIconContainer {

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.iconContainer {

width: 100%;

}

.servicesDes {
  font-size: 16px;
  width: auto;
  min-width: auto;
}

.desHeading {
  font-size: 32px;
}
.subIconContainer {
  padding: 20px;
width:fit-content;
}
.desHeading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.servicesDes{
  font-size: 18px;
  font-weight: 400;
  color: #7A7A7A;
  text-align: center;
margin-bottom: 10px;
}
.desContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}


}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {

  .mainServiceContainer {
    padding: 100px 60px;
}
.mainIconContainer {

  flex-direction: row;
  gap: 10px;
}
.iconContainer {

width: 100%;

}

.servicesDes {
  font-size: 20px;
  width: auto;
  min-width: auto;
}

.desHeading {
  font-size: 38px;
}
/* .subIconContainer {
  padding: 40px 30px 30px 40px;

} */
}

@media only screen and (min-width: 1025px) and (max-width: 1251px) {

  .mainServiceContainer {
    padding: 100px 100px;
}
.mainIconContainer {

  flex-direction: row;
  gap: 15px;
}
.iconContainer {

width: 100%;

}

.servicesDes {
  font-size: 20px;
  width: auto;
  min-width: auto;
  width: 550px;
}
.iconBackgroundContainer{ width: 550px;}
.desContainer {
   
  width:auto;
}

.subIconContainer {
  padding: 20px;

}
}


@media only screen and (min-width: 1250px) and (max-width: 1672px) {

  .mainServiceContainer {
      padding: 100px 50px;
      flex-wrap: nowrap;
  }


  .objectiveList {
      font-size: 20px;
      text-align: justify;
  }
  .iconBackgroundContainer{ width: 550px;}
  .mainIconContainer {

    flex-direction: row;
    gap: 8px;
  }
  .desContainer {
   
    width:auto;
  }
  
}

@media only screen and (min-width: 1673px) and (max-width: 1799px) {
  .mainServiceContainer {
      padding: 100px 250px;
  }

  .desContainer {
   
    width:auto;
  }
  
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
  .mainServiceContainer {
      padding: 100px 300px;
      flex-wrap: nowrap;
  }
  .desContainer {
   
    width:auto;
  }
  
}