.user-popup{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.user-popup-head{
    color: var(--Accent-Color, #00517C);
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* padding: 1px 20px 0px 20px; */
}
.user-popup-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.user-popup-image-container{
    min-width: 300px;
    padding: 8px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}
.user-popup-image-path{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}
.user-popup-image-path:hover{
    white-space:normal;
    position: relative;
}
.user-popup-image-preview-container {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 1px #0173a740
}


.user-popup-image-uploader-label {
    padding: 10px;
    cursor: pointer;
    background-color: #0173A7;
    color: white;
    border: none;
    border-radius: 8px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0173A7;
    border: none;
    color: white;
    padding: 9px 10px;
    margin: 5px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600 !important;
    min-width: 200px;
    height: 38px;
}

.user-popup-image-uploader-label:hover {
    box-shadow: 0px 0pc 10px 0px #00517c67;

}

.user-popup-image-uploader {
    display: none;
}

.user-popup-button-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    
}

.loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px; /* Set a fixed height or adjust as needed */
  }
 /* Mobile styles */
 @media only screen and (max-width: 600px) {
    .user-popup-container {
        display: flex;
       flex-wrap: wrap;
    }
    .user-popup-button-container {
      
justify-content: center;     
   flex-wrap: wrap;
        
    }
    .user-popup-head {
    
        font-size: 24px;
    }
    /* Add more mobile-specific styles as needed */
  }
  
  /* Tablet styles */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .title-card-title {
    
        font-size: 38px;
    }
  
    /* Add more tablet-specific styles as needed */
  }
  
  /* Large screen styles */
  @media only screen and (min-width: 1025px) {
    body {
      /* background-color: #cccccc; */
    }
  
    /* Add more large screen-specific styles as needed */
  }
  