.slideshow-container {
  position: relative;
  max-width: 587px;
  max-height: 587px;
  /* width: auto;
  height: 100%; */
  border-radius: 10px;
}

.slideshow-container:hover {
  position: relative;
  max-width: 587px;
  max-height: auto;
  width: auto;
  height: 100%;
}

.slide {
  display: none;
  transition: opacity 0.5s ease; /* Add transition property for opacity */

}

.slide.active {
  display: block;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 1px;
  opacity: 1; /* Show active slide with smooth transition */
  min-width: 35vw;
}

.slide:not(.active) {
  opacity: 0; /* Hide inactive slides with smooth transition */

}

.slide img {
  width: 587px;
  height: 400px;
  width: 100%;
  border-radius: 10px;
object-fit: cover;
/* height: auto; */
}

.prev,
.next {
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: white;
  font-size: 10px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.prev:hover,
.next:hover {
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: 900;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.dot-container {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #ffffff;
  border-radius: 50%;
}

/* Smartphones */
@media only screen  and (max-width: 767px) {
  .slide img {
    width: 487px;
    height: 300px;
    width: 100%;
    border-radius: 10px;
  object-fit: cover;
  }
  
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  
  
}


@media only screen and (min-width: 1400px) and (max-width: 1799px) {
 
  

}

/* Large monitors */
@media only screen and (min-width: 1800px) {
  
  
}
