.main-login-container {
  background-color: #195776;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* margin: 0; */
}

.login-card-container {
  display: flex;
  align-items: center;
  width: 950px;
  height: 550px;
  background-color: white;
  border-radius: 20px;
}

.login-image-container {
  
}

.login-image {
  max-height: 500px;
  max-width: 500px;
  width: 100%;
  height: 100%;
}

.login-form-container {
  display: flex;
  flex-direction: column;
}

.login-logo-container {
  padding-left: 20px;
}

.login-heading {
  font-size: 24px;
  font-weight: 700;
  padding-left: 20px;
}

.login-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input {
  border: 1px solid #195776;
  width: 310px;
  height: 35px;
  padding: 10px;
  border-radius: 10px;
  border-width: 2px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
  text-indent: 46px;
  color: #000000;
}

.custom-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #7a7a7a;
}

.inputbox-container {
  position: relative;
  margin: 20px;
}
.inputbox-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

/* Smartphones */
@media only screen and (max-width: 767px) {
  .login-image-container {
    display: none;
  }
  .custom-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #7a7a7a;
  }
  .login-card-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.main-login-container{
  background-color: #ffffff;;
}
}
