.custom-table-container {
    background: white;
    border-radius: 20px;
    width: auto;
    border-collapse: collapse;
    color: black !important;
    border-radius: 10px !important;
    overflow: scroll;
    margin: 20px 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
}

.custom-table {

    border-collapse: collapse;
    margin: 10px;


}

.custom-table-head {
    /* background-color: #0173A7; */
    color: rgb(255, 255, 255);
    height: 51px;
    border: none;
    border-collapse: collapse;

}

.custom-table-head tr th {
    background-color: #0173A7;
    color: rgb(255, 255, 255);
    height: 51px;
    border: none;
    border-radius: 3px;
    font-weight: 700;
    box-shadow: 0px 0px 6px 0px #0173A7;
}
.custom-table-tr{
    border-radius: 10px;
    background: var(--bg, rgba(234, 243, 252, 0.20));
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    height: 55px;
    margin-top: 10px;

}
.custom-table-tr:hover{
font-weight: 800; 
   box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.60);
   

}
.align-center{
    text-align: center;
}
.custom-table-tr td{ 
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

 /* Mobile styles */
 @media only screen and (max-width: 600px) {

    .custom-table-tr td{ 
        font-size: 10px;
        width: 10px !important;
        overflow: hidden;
    }
    


  }