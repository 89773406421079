.time-range-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family:Poppins, sans-serif;
    font-weight: 700;
    width: auto; /* Adjust width as needed */
    flex-wrap: wrap;

  }
  
  .time-input {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .time-display {
    font-size: 18px;
    margin-top: 10px;
  }
  