.ObjectiveMainContainer {
    background-color: #ececec;
    padding: 100px 200px;
    display: flex;
    align-items: center;
    padding: 100px 100px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
}

.ObjectiveImageContainer {
    display: flex;
    align-items: center;
}

.ObjectiveImage {
    max-width: 552px;
    max-height: 379px;
    width: 100%;
    height: 100%;
    /* padding: 88px 100px; */
    display: flex;
}

.ObjectiveContainer {
    /* text-align: left; */
    display: flex;
    /* padding: 95px 0 0px 0px; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.objectiveList {
    font-size: 16px;
    width: 450px;
    max-width: fit-content;

}

.ObjectiveHeading {
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 2px;

}

.objective-button-text{
    display: none;
}





/* Smartphones */
@media only screen and (max-width: 767px) {

    .ObjectiveMainContainer {
        padding: 100px 20px;
    }

    .objectiveList {
        font-size: 16px;
        width: auto;
        min-width: auto;
    }

    .ObjectiveHeading {
        font-size: 30px;
    }

    .objectiveList {
        font-size: 12px;
        text-align: justify;
    }
    .ObjectiveContainer{
        display: flex;
        /* padding: 95px 0 0px 0px; */
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .objective-button-text{
        display: block;
        text-decoration:underline ;
    }
    .objective-button-text:hover{
        color: #fff;
    }
    .objective-button{
        display: none;
    }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .ObjectiveMainContainer {
        padding: 100px 60px;
    }

    .ObjectiveHeading {
        font-size: 38px;
    }

    .objectiveList {
        font-size: 16px;
        text-align: justify;
        width: 100%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1251px) {
    .ObjectiveMainContainer {
        padding: 100px 100px;
    }


    .objectiveList {
        font-size: 16px;
        text-align: justify;
        width: 550px;

    }
}


@media only screen and (min-width: 1250px) and (max-width: 1672px) {

    .ObjectiveMainContainer {
        padding: 100px 50px;
    }


    .objectiveList {
        font-size: 16px;
        text-align: justify;
    }
}

@media only screen and (min-width: 1673px) and (max-width: 1799px) {
    .ObjectiveMainContainer {
        padding: 100px 250px;
    }

  
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
    .ObjectiveMainContainer {
        padding: 100px 300px;
    }
}