.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 100px 250px;
  background-color: rgb(255, 255, 255);

}

.mainContactContainer {
  /* height: 680px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

}

.mainContactContainer {
  display: flex;
}

.contactDesContainer {
  /* max-width: 450px; */
  text-align: left;
  /* padding-left: 70px; */
  padding-top: 50px;
}

.contactIcon {
  width: 20px;
  height: 17px;
}

.inputBoxContainer {
  /* padding-left: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contactDesHeading {
  font-size: 48px;
  font-weight: 600;
}

.contactDes {
  font-size: 24px;
  font-weight: 400;
  color: #7a7a7a;
  /* padding-bottom: 40px; */
  /* margin-top: -20px; */
}

.iconDesContainer {
  display: flex;
  padding-bottom: 1px;
}

.iconDes {
  padding-left: 23px;
  margin-top: -6px;
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
}

.inputBoxContainer {
  background-color: rgb(255, 255, 255);
  /* padding-left: 70px; */
  padding-top: 80px;
}

.subInputBoxContainer {
  background-color: rgb(255, 255, 255);
  max-width: 570px;
  max-height: 650px;
  box-shadow: 0 0 8px #195776;
  border-radius: 10px;
  gap: 10px;
  padding: 90px 40px;
  display: flex;
  flex-direction: column;
}

.contact-details-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.contact-details {
  color: #585656;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contact-details p:first-child {
  color: #8d8b8b;
  font-weight: bold;
}

.contact-details div:first-child {
  color: #195776;
  font-weight: 700;

}

.contact-details div {
  font-size: 14px;
  color:black;

}
.contact-details a {
  text-decoration: none ;
  color:black;
  
    }
/* Add these styles to your existing Gallery.css file */
/* .map-preview-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.map-preview-iframe iframe {
  width: 80%;
  height: 80%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
} */

/* Smartphones */
@media only screen and (max-width: 766px) {
  .mainContainer {
    padding: 20px 20px;
    flex-direction: row;
    align-items: center;
  }

  .subContactFooterContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .contactDesHeading {
    font-size: 30px;
    text-align: center;
  }

  .contactDes {
    text-align: center;
    font-size: 20px;
  }

  .inputBoxContainer {
    width: 100%;
    max-width: 370px;

  }

  .subInputBoxContainer {
    max-width: 370px;
    padding-top: 40px;
    padding-bottom: 30px;
    padding: auto;
  }

  .mainContactContainer {
    justify-content: space-around;
  }

  .contact-details div {
    font-size: 14px;
  }

}

/* Tablets */
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .mainContainer {
    padding: 10px 60px;
  }

  .mainContactContainer {
    justify-content: space-around;
  }

  .subContactFooterContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {

    width: 200px;
    min-height: 150px
  }

  .contactDesContainer {
    max-width: 100%;
    text-align: left;

  }

  .subInputBoxContainer {
    background-color: rgb(255, 255, 255);
    max-width: 570px;
    max-height: 650px;
    box-shadow: 0 0 8px #195776;
    border-radius: 10px;
    padding: 80px 60px;
  }
}

/* Tablets */
@media only screen and (min-width:1025px) and (max-width: 1251px) {
  .mainContainer {
    padding: 10px 150px;
  }

  .subContactFooterContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {

    width: 200px;
    min-height: 150px
  }

  .contactDesContainer {
    max-width: 100%;
    text-align: left;

  }

  .subInputBoxContainer {
    background-color: rgb(255, 255, 255);
    max-width: 470px;
    max-height: 650px;
    box-shadow: 0 0 8px #195776;
    border-radius: 10px;
    padding: 80px 20px;
  }

  .mainContactContainer {
    justify-content: space-around;
  }
}

@media only screen and (min-width: 1252px) and (max-width: 1401px) {
  .mainContainer {
    padding: 100px 50px;
  }

  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {

    width: 300px;
    min-height: 150px
  }

  .contactDesContainer {
    max-width: 540px;
    text-align: left;

  }

  .subInputBoxContainer {
    background-color: rgb(255, 255, 255);
    max-width: 470px;
    max-height: 650px;
    box-shadow: 0 0 8px #195776;
    border-radius: 10px;
    padding: 80px 20px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .mainContainer {
    padding: 100px 9vw;
  }

  .contactDesContainer {
    max-width: auto;
    text-align: left;

  }

  .subInputBoxContainer {

    padding: 90px 20px;
  }

  .contactDesContainer {
    max-width: 500px;
    text-align: left;

  }
}

/* largge monitor */
@media only screen and (min-width: 1800px) {
  .mainContainer {
    padding: 100px 300px 0px 300px;
  }

  .contactDesContainer {
    max-width: 650px;
    text-align: left;

  }

  .subInputBoxContainer {
    margin-bottom: -100px;

  }
}
/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {

  .mainContainer {
    padding: 100px 60px;
}
.mainIconContainer {

  flex-direction: row;
  gap: 10px;
}
.iconContainer {

width: 100%;

}

.servicesDes {
  font-size: 20px;
  width: auto;
  min-width: auto;
}

.desHeading {
  font-size: 38px;
}
/* .subIconContainer {
  padding: 40px 30px 30px 40px;

} */
}

@media only screen and (min-width: 1025px) and (max-width: 1251px) {

  .mainContainer {
    padding: 100px 100px;
}
.mainIconContainer {

  flex-direction: row;
  gap: 15px;
}
.iconContainer {

width: 100%;

}

.servicesDes {
  font-size: 20px;
  width: auto;
  min-width: auto;
  width: 550px;
}
.iconBackgroundContainer{ width: 550px;}
.desContainer {
   
  width:auto;
}

.subIconContainer {
  padding: 20px;

}
}


@media only screen and (min-width: 1250px) and (max-width: 1672px) {

  .mainContainer {
      padding: 100px 100px;
      flex-wrap: nowrap;
  }


  .objectiveList {
      font-size: 20px;
      text-align: justify;
  }
  .iconBackgroundContainer{ width: 550px;}
  .mainIconContainer {

    flex-direction: row;
    gap: 8px;
  }
  .desContainer {
   
    width:auto;
  }
  
}

@media only screen and (min-width: 1673px) and (max-width: 1799px) {
  .mainContainer {
      padding: 100px 250px;
  }

  .desContainer {
   
    width:auto;
  }
  
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
  .mainContainer {
      padding: 100px 300px;
      flex-wrap: nowrap;
  }
  .desContainer {
   
    width:auto;
  }
  
}