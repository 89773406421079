.main-about-container {
    align-items: center;
    padding: 10px 100px;
    flex-direction: row;
    background-color: #195776;
    justify-content: space-around;
    /* padding-bottom:50px; */
    height: calc(100vh );
    
}
.about-heading{
    display: flex;
    font-size: 40px;
}
.about-image-container{
    display: flex;
}
.about-heading-container{
    font-size:54px;
    font-weight: 700;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:130px;
}

.about-heading-des-container{
    font-size:20px;
    font-weight: 700;
    color: #FFFFFF;

}

.sydney-isometric{
    max-width:240px;
    max-height:180px;
    width: 100%;
    height: 100%;
    padding-bottom: 120px;
}

.user-group{
    max-width:354px;
    max-height:468px;
    width: 100%;
    height: 100%;

}

.smiley-face{
    max-width:200px;
    max-height:177px;
    width: 100%;
    height: 100%;
    padding-bottom: 120px;
}

.pdf-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}
.pdf-container a{
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;}

    /* Smartphones */
@media only screen  and (max-width: 500px) {
    .main-about-container {
        padding: 10px 50px;
        height: calc(100vh - 115px);

    }
    .sydney-isometric{
        max-width:60px;
        max-height:180px;
        display: none;

    }
    
    .user-group{
        max-width:450px;
        max-height:468px;
       
    
    }
    
    .smiley-face{
        max-width:50px;
        max-height:177px;
        display: none;
        
    }
    .about-heading-container{
        padding-top:10px;
        font-size: 25px;
        gap: 40px;

    }
    .about-heading-des-container{
        font-size:14px;
       
    
    }
    .about-heading{
        display: flex;
        font-size: 30px;
        text-align: center;
    }
    .pdf-container a{
        font-size: 14px;
    }
}
/* Smartphones */
@media only screen and (min-width: 500px)  and (max-width: 767px) {
    .main-about-container {
        padding: 10px 50px;
        height: calc(100vh - 135px);

    }
    .sydney-isometric{
        max-width:75px;
        max-height:180px;
       
    }
    .about-heading{
        display: flex;
        font-size: 30px;
        text-align: center;
    }
    .user-group{
        max-width:280px;
        max-height:768px;
       
    
    }
    
    .smiley-face{
        max-width:80px;
        max-height:177px;
        
    }
    .about-heading-container{
        padding-top:10px;
        font-size: 25px;
        gap: 50px;

    }
    .about-heading-des-container{
        font-size:16px;
       
    
    }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .main-about-container {
        padding: 100px 150px;
        flex-direction: row;
        height: calc(100vh - 275px);

    }
    .sydney-isometric{
        max-width:110px;
        max-height:180px;
       
    }
    
    .user-group{
        max-width:320px;
        max-height:468px;
       
    
    }
    
    .smiley-face{
        max-width:160px;
        max-height:177px;
        
    }
    .about-heading-container{
        padding-top:30px;
        font-size: 45px;
        gap: 0px;

    }
    .about-heading-des-container{
        font-size:18px;
       
    
    }
}
@media only screen and (min-width: 1026px) and (max-width: 1401px) {
    .main-about-container {
        padding: 100px 250px;
    }
    .user-group{
        max-width:270px;
        max-height:468px;
       
    
    }
    .sydney-isometric{
        max-width:140px;
        max-height:180px;
       
    }
    .smiley-face{
        max-width:130px;
        max-height:177px;
        
    }
    .about-heading-container{
        padding-top:30px;
        font-size: 45px;
        gap: 50px;

    }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
    .main-about-container {
        padding: 100px 250px;
    }
    .ObjectiveImage {
        max-width: 500px;
        max-height: 350px;
      
      }
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
    .main-about-container {
        padding: 80px 300px;
        height: calc(100vh - 275px);

    }
}
  