
.custom-input-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom:10px;
  flex-direction: column;

}
.custom-input-container-top{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.custom-input-error-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.error-message {
  color: red;
  font-size: 11px;
  height: 0px;
}
.custom-input-container div{
  /* min-width: 180px; */
}
.custom-label{
  color: #1D1F28;
  width: 100px;
  margin-right:  10px;
  font-weight: 600;
  font-size: 16px;
  width: 400px;
}
.custom-label-normal{
  color: #1D1F28;
  width: 100px;
  margin-right:  10px;
  font-weight: 600;
  font-size: 16px;
  width: 200px;
}
.custom-input, .custom-input-textArea {
  border: 1px solid #b6b5b5 ;
  /* height: 42px; */
  border-radius: 5px;
  display: flex;
  width: 450px;
  /* height: 56px; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: #A5A5A9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 24px; */

}
.custom-input-with-label {
  border: 1px solid #b6b5b5 ;
  border-radius: 5px;
  width: 480px;
  padding: 16px;
  gap: 10px;
  color: #A5A5A9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 400px;
}

.custom-input::placeholder{
  color: #A5A5A9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.custom-input:focus {
  /* border-color: #F36D13 ; */
outline-color:  #F36D13;
}
.custom-input option{
  color: #A5A5A9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.address-input-container{
  display: flex;
  flex-direction: column;
}
.address-input {
  margin-bottom:10px;
  color: #A5A5A9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 56px;  
  width: 480px;
}

.address-container{
  align-items: flex-start;

}
/* CustomInput.css */
.switch {
position: relative;
display: inline-block;
width: 33px;
height: 20px;
}

.switch input {
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #d3d3d3;
-webkit-transition: 0.4s;
transition: 0.4s;
border: 1px solid grey;
}

.slider:before {
position: absolute;
content: '';
height: 16px;
width: 15px;
left: 2px;
right: 0px;
bottom: 1px;
background-color: #484A51;
-webkit-transition: 0.4s;
transition: 0.4s;
}

.switch-button:checked + .slider {
background-color:#FFFFFF;
}

.switch-button:focus + .slider {
box-shadow: 0 0 1px #000000;
}

.switch-button:checked + .slider:before {
-webkit-transform: translateX(12px);
-ms-transform: translateX(12px);
transform: translateX(12px);
}

.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}
.custom-option {
font-size: 16px;
color: #0f0fce !important;
/* Add other styles as needed */
}



/* Mobile styles */
@media only screen and (max-width: 400px) {
.custom-label{

  width:auto;
 
}
.custom-input, .custom-input-textArea {
 
  width: 100%;
  max-width:170px;


}
.custom-input::placeholder {
 
  font-size: 12px !important;

}
.custom-input::placeholder, .custom-input-textArea::placeholder {
 
  font-size: 12px;

}
.custom-input-with-label
{
 
  width: 100%;
min-width:180px;
}
/* Add more mobile-specific styles as needed */
}

/* Mobile styles */
@media only screen and (max-width: 520px) {
  .custom-label{
  
    width:auto;
   
  }
  .custom-input, .custom-input-textArea {
    min-width:150px;

    width: auto;
  
  }
  .custom-input::placeholder {
 
    font-size: 15px ;
  
  }
  .custom-input-with-label
  {
    max-width:280px;

    width:auto;
  min-width:250px;
  }

  /* Add more mobile-specific styles as needed */
  }

@media (max-width: 767px) {
.custom-input,.custom-input-textArea {
  min-width:150px;

  width: -webkit-fill-available;

}
.custom-input-with-label
{
 
  width: auto;

} 

.custom-input-container div{
  /* min-width: 170px; */
}
.custom-input-container div:first-child {
  min-width: 35vw;
}
}
