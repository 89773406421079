


.about-ObjectiveMainContainer {
    background-color: #ececec;
    display: flex;
    padding: 50px 200px;
    flex-direction: column;
    align-items: center;
 
  }
  .about-ObjectiveImageContainer{
    display: flex;
    align-items: center;
  }
  .about-ObjectiveImage {
    max-width: 552px;
    max-height: 379px;
    width: 100%;
    height: 100%;
    /* padding: 88px 100px; */
    display: flex;
  }
  
  .about-ObjectiveContainer{
        /* text-align: left; */
        display: flex;
        /* padding: 95px 0 0px 0px; */
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
  }
  .about-ObjectiveList{
      font-size: 16px;
      gap: 10px;

  }
  .about-ObjectiveList li {
    margin: 20px 0;
    text-align: justify;
  }
  
  .about-ObjectiveHeading{
      font-weight: 600;
      font-size:54px;
     
  
  }
  
  
    
  
/* Smartphones */
@media only screen  and (max-width: 767px) {
    .about-ObjectiveMainContainer {
        padding: 100px 50px;
    }
    .about-ObjectiveList{
        font-size: 12px;
        gap: 10px;
  
    } .about-ObjectiveHeading{
        font-weight: 600;
        font-size:30px;
    }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .about-ObjectiveMainContainer {
        padding: 100px 150px;

    }
    .about-ObjectiveImage {
        max-width: 450px;
        max-height: 350px;
      
      }
      .about-ObjectiveList{
        font-size: 14px;
        gap: 10px;
  
    } 
}


@media only screen and (min-width: 1400px) and (max-width: 1799px) {
    .about-ObjectiveMainContainer {
        padding: 100px 250px;
    }
    .about-ObjectiveImage {
        max-width: 500px;
        max-height: 350px;
      
      }
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
    .about-ObjectiveMainContainer {
        padding: 100px 300px;
    }
}
  