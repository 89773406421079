.mainEventContainer {
    align-items: center;
    padding: 100px 100px;
    flex-direction: row;
    background-color: #195776;
    height:calc(100vh - 275px);
    justify-content: space-around;
    display: flex;
}

.eventPageHeading {
    font-size: 54px;
    font-weight: 600;
    color: white;
    max-width:340px;
}

.eventPageDes {
    font-size: 16px;
    font-weight: 500;
    color: white;
    max-width:340px;
}

.eventPageImage {
    max-width:673px;
    max-height:574px;
    width: 100%;
    height: 100%;
}

 /* Smartphones */
 @media only screen and (max-width: 766px) {
    .mainEventContainer {
        align-items: center;
        padding: 0px 50px;
        flex-direction:column;
        background-color: #195776;
        justify-content: space-around;
        display: flex;
        height: calc(100vh - 115px);

    }
    .eventPageImage {
     margin-top:0px;
   

    }
    .eventPageHeading {
        font-size: 30px;
        text-align: center;
    }
    .eventPageDes {
        text-align: center;
        font-size: 14px;
    }
    
  }
