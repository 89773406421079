/* header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 10px; */
    background-color: #fff;
    color: white;
    z-index: 999;
    position: sticky;
    width: 100%;
    /* height: 35px; */
    top: 0;
    
  }
  
  .header-left {
    display: flex;
    align-items: center;
    color: #00517C;
    
  }
  .header-left i {
  padding: 10px;
    
  }
  .header-logo {
    width:90px; /* Adjust the width as needed */
    height: auto;
    margin-left: 10px;
    z-index: 999;
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .notification {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .profile {
    cursor: pointer;
  }
  
  .avatar {
    width: 45px; /* Adjust the width as needed */
    height: auto;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .profile-menu {
    position: absolute;
    top:0;
    right: 0;
    color: #333;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 55px;
    z-index: 1;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    transition:all 1.7s;
    min-width: 150px;
  }
  .profile-menu button {
   border: none;
   background:#333;
   padding: 10px;
   color: #fff;
   border-radius: 10px;
   transition:all 1.7s;
  }
  .profile-menu > * {
    margin-bottom: 10px;
  }
  
  /* Add additional styling as needed */
  