.event-btn-container{
    display: flex;
    padding: 0px 20px;
    margin-bottom: -22px;
}

 .event-btn-container button{
    background: #0173A7;
    border-radius: 5px 20px  0% 5px ;
    margin:0px;
    transition: all 0.7s;
}

.event-btn-container button{
    background: #0173A7;
    border-radius: 5px 20px  0% 5px ;
    margin:0px;
}
.event-btn-container button:active{
    background: rgb(255, 255, 255);
    color: #0173A7;
}