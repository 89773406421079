.event-container {
    background-color: #ececec;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10px;
    padding: 100px 100px;
    flex-wrap: wrap;

}

.eventContainer {
    /* padding-left: 90px; */
    /* padding-top: 70px; */
}

.events {
    display: flex;
  max-width: 780px;
  min-height: 181px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 40px;
  border-radius:10px;
  border-bottom: 6px solid #195776;
}

.eventDesBox{
    /* padding-left: 33px; */
    /* padding-top: 40px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;


}
.eventDesHeading-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    max-width: 400px;
}
.subEventBox{
    background-color: rgb(255, 255, 255);
    border-top: 3px solid #195776;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 20px;
    
}


.subEventBoxText{
    color:#195776 ;
    font-size: 16px;
    font-weight:700;
    /* padding-top: 12px; */
    text-align: center;
    width: 100%;

}
.subEventBox p{
   padding: 1px;
}

.subEventDes{
    margin-top: -20px; 
    padding-left: 15px;
    max-width: 501px;

}
.subEventDesHeading{
    font-size: 24px;
    font-weight:700;
    text-align: left;
}

.subEventDesText{
    font-size: 16px;
    font-weight:400;
    text-align: left;
    /* margin-top:-5px; */
    color: #7A7A7A;
}

.eventDesContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* max-width: 300px; */

}

.event-head{
    color: var(--blue, #195776);
    font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.eventDesHeading{
    font-size: 48px;
    font-weight: 600;
    text-align: left;
}

.eventDesText{
    font-size: 24px;
    font-weight:400 ; 
    color: #7A7A7A;
    text-align: left;
}
.learnMore-btn{ display: flex;

}
.learnMore-txt{ display: none;}
/* Smartphones */
@media only screen  and (max-width: 767px) {
    .event-container {
        padding: 100px 50px;
        flex-direction: column-reverse;
align-items: center;
    }
    .eventDesBox{
        justify-content: center;
    }
    .eventDesHeading-container {
        font-size:30px;
         max-width: 100%;
         text-align: center;
         flex-direction: column;

     }
    .eventDesHeading-container div{
        font-size:30px;
         max-width: 100%;
         text-align: center;
     }
     .eventDesText{
         font-size: 18px;
         text-align: center;

     }
     .subEventDesHeading{
        font-size: 20px;
        text-align: center;
    }
    .subEventDesText{
        font-size: 14px;
        text-align: center;

    }
    .learnMore-txt{ text-align: center;
        display: block;
        text-decoration: underline;
        }
        .learnMore-txt:hover{ color: #fff;
            }
        .learnMore-btn{ display: none;

        }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .event-container {
        padding: 100px 150px;
        flex-direction: column-reverse;

    }
    .subEventDes{
        margin-top: -20px; 
        padding-left: 15px;
        max-width: 401px;
    
    }
    .eventDesBox{
        justify-content: center;
    }
    .events {
     
    }
    .eventDesHeading-container div{
       font-size:40px;
        max-width: 100%;
    }
    .eventDesText{
        font-size: 18px;
    }
   
}
@media only screen and (min-width: 1026px) and (max-width: 1155px) {
    .event-container {
        padding: 100px 250px;
        flex-direction: column-reverse;
        align-items: center;

    }
    .subEventDes{
        margin-top: -20px; 
        padding-left: 15px;
        max-width: 271px;
    
    }
    .eventDesHeading-container{
       
        max-width: 100%;
    }
   
}
@media only screen and (min-width: 1156px) and (max-width: 1250px) {
    .event-container {
        padding: 100px 150px;
        flex-direction: column-reverse;
align-items: center;
    }
    .subEventDes{
        margin-top: -20px; 
        padding-left: 15px;
        /* max-width: 401px; */
    
    }
    .eventDesHeading-container{

        max-width: 100%;
    }

}
@media only screen and (min-width: 1251px) and (max-width: 1399px) {
    .event-container {
        padding: 100px 250px;

    }
    .eventDesContainer{
       
        max-width: 300px;
    
    }
    .subEventDes{
        margin-top: -20px; 
        padding-left: 15px;
        max-width: 401px;
    
    }

}
@media only screen and (min-width: 1400px) and (max-width: 1546px) {
    .event-container {
        padding: 100px 250px;
        /* flex-direction: row-reverse; */
        display: flex;

    }
    .eventDesBox {
        justify-content: center;
      
      }
      .eventDesContainer{
       
        max-width: 450px;
    
    }
}
@media only screen and (min-width: 1547px) and (max-width: 1799px) {
    .event-container {
        padding: 100px 250px;

    }
    .ObjectiveImage {
        max-width: 500px;
        max-height: 350px;
      
      }
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
    .event-container {
        padding: 100px 300px;
    }
}
  





/* Smartphones */
@media only screen and (max-width: 767px) {

    .event-container {
        padding: 100px 20px;
    }

    .objectiveList {
        font-size: 16px;
        width: auto;
        min-width: auto;
    }

    .ObjectiveHeading {
        font-size: 32px;
    }

    .objectiveList {
        font-size: 18px;
        text-align: justify;
    }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .event-container {
        padding: 100px 60px;
    }

    .ObjectiveHeading {
        font-size: 38px;
    }

    .objectiveList {
        font-size: 20px;
        text-align: justify;
        width: 100%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1251px) {
    .event-container {
        padding: 100px 100px;
    }


    .objectiveList {
        font-size: 20px;
        text-align: justify;
        width: 550px;

    }
}


@media only screen and (min-width: 1250px) and (max-width: 1672px) {

    .event-container {
        padding: 100px 50px;
    }


    .objectiveList {
        font-size: 20px;
        text-align: justify;
    }
}

@media only screen and (min-width: 1673px) and (max-width: 1799px) {
    .event-container {
        padding: 100px 250px;
    }

  
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
    .event-container {
        padding: 100px 300px;
    }
}