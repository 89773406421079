body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.dashboard-container {
  background: rgb(224, 237, 250);
  height: auto;
  min-height: 100vh;
}


.nav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #00517C;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
  /* margin-top: 80px; */
  height: 85%;
  width: 20px;
  position: fixed;
  z-index: 1;
  top: 6%;
  left: 10px;
  background: white;
  overflow-x: hidden;
  padding-top: 60px;
  transition: all 0.7s;
  /* margin-top: 80px; */
  border-radius: 10px;
}

.nav ul {
  list-style-type: none;
  padding: 0;

}

.nav li {
  padding: 8px;
  text-align: left;
  text-decoration: none;
  margin-left: 40px;
  display: flex;
  width: 200px;
  border-radius: 10px;
  margin-bottom: 15px;
  height: 35px;
  align-items: center;

}

.nav-short {
  width: 25px;
}

.nav li i {
  color: #343333;
  padding: 5px;
  margin-right: 5px;
}

.nav a {
  text-decoration: none;
  font-size: 16px;
  color: #343333;
  display: block;
  font-weight: 700;

}

.nav-active {
  background-color: #00517C;
  color: #72a3bb;

}

.nav-active a,
.nav-active i {
  color: #ebe8e8 !important;

}

.nav-inactive {
  color: #000000 !important;
  background-color: #72a3bb;

}

.dashboard-main {
  /* margin-left: 200px; */
  padding: 16px;
  overflow: hidden;
  transition: margin-left 0.5s;
  width: auto;
  margin: 10px;

}

.nav-open {
  margin-left: 300px;
  transition: all 0.7s;

}

@media screen and (max-width: 600px) {
  .dashboard-main {
    margin-left: 0;
    margin: 0px;
    padding: 0px
  }

  .nav-open {
    margin-left: 0px;
    transition:all 0.5s;

  }



  .nav {

    /* position: absolute; */
    /* z-index: 999; */
    height: auto;
    min-height: 50vh;
    border-radius: 0px 0px 10px;
    height: 100%;
    width: 100%;
    top: 8%;
    transition: all 0.7s;
    background: none;
    backdrop-filter: blur(35px);
  }

}