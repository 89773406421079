body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins;
  scroll-behavior: smooth;
  transition:   scroll-behavior 0.80s ease;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;

}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Smartphones */
@media only screen and (min-width: 366px) and (max-width: 767px) {
  .community-container {
      padding: 100px 80px;
  }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .community-container {
      padding: 100px 80px;
  }
}

/* Large monitors */
@media only screen and (min-width: 1600px) {
  .community-container {
      padding: 100px 300px;
  }
}

*{scrollbar-width: none ;
}