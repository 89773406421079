/* Toast.css */
.toast {
  position: fixed;
  top: 10px;
  right: 0px;
  transform: translateX(-10%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

.toast.show {
  opacity: 1;
  z-index: 1;
}

.toast-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.success {
  background-color: rgb(11, 72, 52);
}

.error {
  background-color: #D03636;
}

.warning {
  background-color: rgb(244, 154, 80);
}

