/* Popup.css */

/* Styles for the background overlay */
.popup {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  /* Adjust the z-index as needed to control the stacking order */
  justify-content: center;
  align-items: center;
  transition: all 0.7s;

}

.popup-layout {
  padding: 10px;
  margin: 10px;
  min-height: fit-content;
  transition: all 0.7s;

}

/* Styles for the popup content */
.popup-content {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  /* min-height: -webkit-fill-available;
  min-width: -webkit-fill-available; */
transition: all 1.7s;
  overflow: scroll;

  transform: translate(-50%, -50%);
}

/* Styles for the close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

/* Add any additional styles or customizations here */
/* Mobile styles */
@media only screen and (max-width: 450px) {
  .popup-content {
    max-width: 300px;
    max-height: 90vh;
    height: fit-content;
    /* width: fit-content; */
    overflow: scroll;
    width: 100%;
  }
}

/* Mobile styles */
@media only screen and (max-width: 600px) {
  .popup-content {
    overflow: scroll;
    width: 100%;

  }
}

/* Tablet styles */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .popup-content {

    overflow: scroll;
    max-height: 90vh;
    height: fit-content;
    max-width: fit-content;
  }

  /* Add more tablet-specific styles as needed */
}

/* Large screen styles */
@media only screen and (min-width: 1025px) {

  /* Add more large screen-specific styles as needed */
}