.delete-popup{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    align-items: flex-start;

  }
.delete-popup-head{
    color: var(--Accent-Color, #D03636);
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0px 20px 0px 0px;
    
}
.delete-popup-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;

}







.delete-popup-button-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    /* flex-wrap: wrap; */

}


 /* Mobile styles */
 @media only screen and (max-width: 600px) {
    .delete-popup-container {
        display: flex;
       flex-wrap: wrap;
    }
    /* Add more mobile-specific styles as needed */
    .delete-popup-button-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    /* flex-wrap: wrap; */

}
.delete-popup-head{

  font-size: 24px;

  
}
  }
  
  
  /* Tablet styles */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .title-card-title {
    
        font-size: 38px;
    }
  
    /* Add more tablet-specific styles as needed */
  }
  
  /* Large screen styles */
  @media only screen and (min-width: 1025px) {
    body {
      /* background-color: #cccccc; */
    }
  
    /* Add more large screen-specific styles as needed */
  }
  