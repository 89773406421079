.mainEventUpdateContainer {
    display: flex;
    padding-top: 60px;
    align-items: center;
    flex-direction: column;
    background-color: #F7F7F7;
    padding-bottom: 60px;
    height: auto;
}
.Event-heading{
    font-size:54px;
    font-weight: 700;
    font-family: Poppins, sans-serif;
}

.eventUpdateContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 60px;
    padding: 0px 10vw;
   
}

.subEventUpdateContainer {
    display: flex;
    /* Width: 1094px; */
    /* Height: 238px; */
    gap: 48px;
    background: #ececec;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    max-width: fit-content;
    flex: 1;

}

.dateMonthContainer {
    background-color: rgb(255, 255, 255);
    Width: 315px;
    Height: 238px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.dateContainer {
    font-size: 64px;
    font-weight: 600;
}

.monthContainer {
    font-size: 24px;
    font-weight: 700;
    color: #3C8490;
}


.updateContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.updateEventHeadingContainer {
    font-size: 36px;
    font-weight: 700;
}

.updateEventDateContainer {
    font-size: 24px;
    font-weight: 700;
    color: #3C8490;
}

.updateEventDesContainer 
{display:flex;
    font-size: 16px;
    font-weight: 400;
    color: #7A7A7A;
    height: auto;
}

/* Smartphones */
@media only screen and (max-width: 766px) {
    .subEventUpdateContainer {
        display: flex;
        flex-wrap: wrap;
        /* padding-bottom: 150px; */
        max-width:380px;
word-break: break-all;
flex:1;

    }

    .updateContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width:380px;
        /* padding-bottom: 100px; */
        
    }
    .mainEventUpdateContainer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 0px !important;
    }
    .updateEventDateContainer,.updateEventDesContainer {
        text-align: center;
    }
    .Event-heading{
        font-size:30px;
     margin-bottom: 10px;
    }
    .updateEventHeadingContainer {
        font-size: 24px;
    }
    
    .updateEventDateContainer {
        font-size: 18px;
       
    }
    
    .updateEventDesContainer 
    {
        font-size: 14px;
      
    }
    .dateContainer {
        font-size: 50px;
    }
    
    .monthContainer {
        font-size: 20px;
      
    }
    .dateMonthContainer {
        Width: 215px;
        Height: 138px;
       
    }
  }
  
  /* Tablets */
  @media only screen and (min-width: 767px) and (max-width: 1024px) {
    .subEventUpdateContainer {
        display: flex;
        flex-direction: column;   
        /* padding-bottom: 100px; */
        max-width: 730px;

    }
    .mainEventUpdateContainer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 0px;
    }
 

    .updateContainer {

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 730px;
        /* padding-bottom: 100px; */
        
    }
    .updateEventDateContainer,.updateEventDesContainer {
        text-align: center;
    }
    .Event-heading{
        font-size:30px;
     margin-bottom: 10px;
    }
    .updateEventHeadingContainer {
        font-size: 28px;
    }
    
    .updateEventDateContainer {
        font-size: 20px;
       
    }
    
    .updateEventDesContainer 
    {
        font-size: 16px;
      
    }
    .dateContainer {
        font-size: 50px;
    }
    
    .monthContainer {
        font-size: 20px;
      
    }
    .dateMonthContainer {
        Width: 215px;
        Height: 138px;
       
    }
    
  }
  
  @media only screen and (max-width:590px) {
    .subEventUpdateContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .updateContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* max-width:330px; */
        align-items: center;
        
    }
    .mainEventUpdateContainer {
        padding-left:30px;
        align-items: center;
    }
 
  }
 
  @media only screen and (min-width:590px) and (max-width:767px) {
    .subEventUpdateContainer {
        flex-direction: column;   
        /* padding-bottom: 100px; */
        align-items: center;

    
    }
    .mainEventUpdateContainer {
        justify-content: space-around;
        padding-left: 50px;
        align-items: center;

    }
    .updateContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 730px;
        /* padding-bottom: 100px; */
        align-items: center;
        
    }
 
 
}
@media only screen and (min-width:768px) and (max-width:1024px) {
    .subEventUpdateContainer {
        display: flex;
        flex-direction: column;
        /* padding-bottom: 100px; */
        align-items: center;

    }
    .mainEventUpdateContainer {
        justify-content: space-around;
        padding-left: 50px;
    }
    .updateContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 730px;
        /* padding-bottom: 100px; */
        align-items: center;
        
    }
    .mainEventUpdateContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 50px;
    }
 
}