.community-container {
    display: flex;
    align-items: center;
    /* padding: 0px 100px; */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    gap: 34px;
    background-color: #195776;
    height: calc(100vh - 275px)
}

.SubContainer {
    min-width: 200px;
    gap: 10px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.welcomeImageContainer {
    min-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    animation: fade-down-right 1s ease forwards;

}

.welcomeImage {
    max-width: 487px;
    max-height: 487px;
    width: 100%;
    height: 100%;
    animation: fade-down-right 1s ease forwards;

}

.welcomeHeading {
    font-weight: 700;
    font-size: 48px;
    text-align: left;
    color: #fff;
    max-width: 450px;
    opacity: 0; /* Start with opacity 0 */
    animation: fade-down-right 1s ease forwards;
}

.welcomeDes {
    font-weight: 400;
    max-width: 400px;
    font-size: 20px;
    text-align: left;
    color: #fff;
    animation: fade-down-right 1s ease forwards;


}

.community-button {
    padding-bottom: 100px;
    animation: fade-down-right 1s ease forwards;

}

.community-button-text {
    display: none;
}
.welcomeImageContainer img{
width: 50vw;
height: auto;
border-radius: 20px;
box-shadow: 0px 0px 5px white;
}
.welcomeImageContainer img:hover{
   
    box-shadow: 0px 0px 10px rgb(255, 255, 255);
    
    }
    @keyframes fade-down-right {
        0% {
            opacity: 0;
            transform: translate(0, -50px);
        }
        100% {
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
/* Smartphones */
@media only screen and (max-width: 767px) {
    .community-container {
        padding: 10px 20px;
        flex-wrap: wrap-reverse;

    }

    .community-button {
        padding-bottom: 100px;
    }

    .welcomeDes {
        max-width: fit-content;
        font-size: 18px;
        text-align: center;
    }

    .welcomeHeading {
        font-size: 30px;
        text-align: center;
        max-width: 100%;
    }

    .community-container {
        height: calc(100vh - 0px)
    }

    .community-button {
        display: none !important;

    }

    .community-button-text {
        text-align: center;
        display: block;
        text-decoration:underline ;

    }

    .community-button-text:hover {
        color: #fff;
    }

    .SubContainer {
        min-width: 200px;
        gap: 10px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .welcomeImageContainer img{
        width: 90vw;
        height: auto;
        border-radius: 20px;
        box-shadow: 0px 0px 5px white;
        }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .community-container {
        padding: 100px 60px;
        flex-wrap: wrap-reverse;
        
    }

    .community-button {
        padding-bottom: 100px;
    }

    .welcomeDes {
        max-width: 100%;
        font-size: 20px;
    }

    .welcomeHeading {
        font-size: 38px;
        max-width: 100%;

    }

    .SubContainer {
        gap: 10px;
        /* min-width: 200px; */
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .welcomeImageContainer img{
        width: 90vw;
        height: auto;
        border-radius: 20px;
        box-shadow: 0px 0px 5px white;
        }
}

@media only screen and (min-width: 1025px) and (max-width: 1251px) {
    .community-container {
        padding: 100px 100px;
        flex-wrap: wrap-reverse;
    }

    .community-button {
        padding-bottom: 100px;
    }

    .welcomeHeading {
        max-width: 100%;
    }

    .welcomeDes {
        max-width: 550px;
        font-size: 20px;
    }

    .SubContainer {
        gap: 10px;
        min-width: 200px;
    }
    .welcomeImageContainer img{
        width: 70vw;
        height: auto;
        border-radius: 20px;
        box-shadow: 0px 0px 5px rgb(71, 30, 30);
        }
}


@media only screen and (min-width: 1250px) and (max-width: 1672px) {
    .community-container {
        padding: 100px 50px;
    }

    .community-button {
        padding-bottom: 100px;
    }

    .welcomeDes {
        /* max-width: 100%; */
        font-size: 20px;
    }

}

@media only screen and (min-width: 1673px) and (max-width: 1799px) {
    .community-container {
        padding: 100px 250px;
    }

    .community-button {
        padding-bottom: 100px;
    }
    .welcomeImageContainer img{
        width: 40vw;
        height: auto;
        border-radius: 20px;
        box-shadow: 0px 0px 5px rgb(254, 254, 254);
        }
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
    .community-container {
        padding: 100px 300px;
    }
    .welcomeImageContainer img{
        width: 35vw;
        height: auto;
        border-radius: 20px;
        box-shadow: 0px 0px 5px rgb(255, 255, 255);
        }
}