.icon-button{
border: none;
background-color: white;
font-size: auto;
}

.icon-button-icon{
    cursor:pointer
}
.icon-button-icon:hover{
font-size: 20px;}