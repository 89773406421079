


.gallery-management-MainContainer {
    display: flex;
    padding: 0px 200px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 65vh;
   overflow: scroll;
    }
    .gallery-img-delete-container{
        position: relative;
        margin-left: -30px;
        
    }
    .gallery-img-delete-container i{
      font-size: large;
      color:red ;
      background-color: white;
      padding: 5px;
      border-radius: 5px;
    }
    .gallery-img-delete-container i:hover{
        font-size: 24px;
        
      }
    .gallery-management-ImageContainer{
      display: flex;
      align-items: center;
    }
    .gallery-management-Image {
      max-width: 552px;
      max-height: 379px;
      width: 100%;
      height: 100%;
      /* padding: 88px 100px; */
      display: flex;
    }
    
    .gallery-management-Container{
          /* text-align: left; */
          display: flex;
          /* padding: 95px 0 0px 0px; */
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
    }
    .gallery-management-List{
      font-size: 16px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: space-around;
  
    }
    .gallery-management-List img ,.gallery-img-container{
     height: 200px;
     width: 300px;
     border-radius: 10px;
     object-fit: cover;
     display: flex;
     gap: 10px;
    }
    
    .gallery-management-Heading{
        font-weight: 600;
        font-size:54px;
        margin-bottom: 2px;
    
    }
    
    
      /* Add these styles to your existing Gallery.css file */
  .image-preview-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    .image-preview-modal img {
      max-width: 80%;
      max-height: 80%;
      border-radius: 10px;
      cursor: pointer;
    }
    .pagination {
      display: flex;
      flex-direction: row;
      gap: 5px;
      justify-content: center;
  }
    
  /* Smartphones */
  @media only screen  and (max-width: 767px) {
      .gallery-management-MainContainer {
          padding: 10px 50px;
      }
      .gallery-management-List img {
          height: auto;
          width: 100%;
          border-radius: 10px;
      }
      .gallery-management-Heading{
        font-size:30px;
    
    }
  }
  
  /* Tablets */
  @media only screen and (min-width: 768px) and (max-width: 1025px) {
      .gallery-management-MainContainer {
          padding: 10px 100px;
  
      }
      .gallery-management-Image {
          max-width: 450px;
          max-height: 350px;
        
        }
        .gallery-management-List img {
          height: auto;
          width: 100%;
          border-radius: 10px;
      }
    
  }
  
  
  @media only screen and (min-width: 1400px) and (max-width: 1799px) {
      .gallery-management-MainContainer {
          padding: 10px 250px;
      }
      .gallery-management-Image {
          max-width: 500px;
          max-height: 350px;
        
        }
        .gallery-management-List img {
          height: 200px;
          width:295px;
          border-radius: 10px;
         }
  }
  
  /* Large monitors */
  @media only screen and (min-width: 1800px) {
      .gallery-management-MainContainer {
          padding: 10px 300px;
      }
    
  }
    