/* Navbar.css */

.navbar {
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:hsl(200, 65%, 28%);;
  padding: 20px 100px;
  height:75px
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-menu {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-menu li a {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  
}

.nav-menu li a:hover,.active {
  background-color:#3C8490;
  /* box-shadow: 0px 0px 15px 0px rgb(255, 255, 255,0.5); */

}

.active {
  background-color:#3C8490;
  /* box-shadow: 0px 0px 35px 0px rgb(255, 255, 255,0.4); */
  border-radius: 4px;
  padding: 1px;

}

.nav-menu li:active {
  background-color:#3C8490;
  /* box-shadow: 0px 0px 45px 0px rgb(255, 255, 255,0.1); */

}

.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.mobile-menu-icon.open .bar:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.mobile-menu-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.mobile-menu-icon.open .bar:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}
/* 
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: center;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    width: 200px;
    text-align: center;
    background-color: #333;
    position: absolute;
    top: 60px;
    right: 0;
}
.nav-menu.open {
  display: flex;
}

.mobile-menu-icon {
  display: flex;
}
  } */











/* Smartphones */
@media only screen and (max-width: 767px) {

  .navbar {
      padding: 20px 20px;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100px;
    text-align: center;
    background-color:hsl(200, 65%, 28%);
    box-shadow: 0px 0px 15px 0px rgb(255, 255, 255,0.5);
    position: absolute;
    border-radius: 10px;
    top: 60px;
    right: 60px;
    text-align: center;
    padding: 10px 10px;
    z-index: 1;

}
.nav-menu li {
  display: flex;
  text-align: center;
  padding: 1px;
}
.nav-menu li a:hover{
  box-shadow: 0px 0px 15px 0px rgb(255, 255, 255,0.5);

  /* padding: 10px 0px; */
}

.nav-menu.open {
  display: flex;
}

.mobile-menu-icon {
  display: flex;
}
.logo{
  height: 50px;
}

}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .navbar {
      padding: 20px 60px;
  }

  .logo{
    height: 60px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1251px) {
  .navbar {
      padding: 20px 100px;
  }

  .logo{
    height: 70px;
  }
}


@media only screen and (min-width: 1250px) and (max-width: 1672px) {

  .navbar {
      padding: 20px 50px;
  }

  .logo{
    height: 80px;
  }
}

@media only screen and (min-width: 1673px) and (max-width: 1799px) {
  .navbar {
      padding: 20px 250px;
  }
  .logo{
    height: 90px;
  }

}

/* Large monitors */
@media only screen and (min-width: 1800px) {
  .navbar {
      padding: 20px 300px;
  }
}