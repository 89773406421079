.nodata-contianer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nodata-contianer img {
    max-width: 600px;
    max-height: 600px;
    width: 100%;
    height: auto;
}