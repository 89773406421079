.contactFooterContainer {
  background-color: #195776;
  min-height: 350px;
  /* padding-top: 100px; */
  /* padding: 100px 250px; */

}

.subContactFooterContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  gap: 30px;
}

.footerHeading {
  color: white;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
}

.desText {
  font-weight: 400;
  color: white;
  font-size: 15px;
}
.desText a{
text-decoration: none;
color: white;
}
.desText li{
  /* margin-left: -50px;  */
 }
.desText a:hover{
  text-decoration:underline;
  color: white;
  }

.logoContainer {
  /* padding-left:80px; */
  /* padding-top: 32px; */
  /* width: 250px; */
  /* min-height: 150px */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.welcomeDes{
  font-size: 14px;
  

}
.logo{
  width: 80px;
}
.socialMediaContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 250px;
  min-height: 150px;
  align-items: flex-start;
}

.linksContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 150px
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 150px
}

.copyrightContainer {
  padding-top: 0px;
  text-align: center;

}


/* Smartphones */
@media only screen and (max-width: 766px) {
  .contactFooterContainer {
    padding: 20px 50px;
    flex-direction: row;
    align-items: center;
  }

  .subContactFooterContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {
    align-items: center;

    width: 200px;
    min-height: 150px
  }
  .logo{
    width: auto;
  }
}

/* Tablets */
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .contactFooterContainer {
    padding: 10px 150px;
  }

  .subContactFooterContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {
    align-items: center;

    width: 200px;
    min-height: 150px
  }
  .logo{
    width: auto;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1401px) {
  .contactFooterContainer {
    padding: 50px 250px;
  }

  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {
    align-items: center;

    width: 300px;
    min-height: 150px
  }
  .logo{
    width:auto;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .contactFooterContainer {
    padding: 40px 250px;
  }

  .logoContainer,
  .socialMediaContainer,
  .linksContainer,
  .contactsContainer {
    align-items: center;

    width: 200px;
    min-height: 150px
  }
 
  .logo{
    width: auto;
  }
}

/* largge monitor */
@media only screen and (min-width: 1800px) {
  .contactFooterContainer {
    padding: 100px 250px;
  }
}