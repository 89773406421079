.gallery-MainContainer {
  display: flex;
  padding: 100px 200px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

}

.gallery-ImageContainer {
  display: flex;
  align-items: center;
}

.gallery-Image {
  max-width: 552px;
  max-height: 379px;
  width: 100%;
  height: 100%;
  /* padding: 88px 100px; */
  display: flex;
}

.gallery-Container {
  /* text-align: left; */
  display: flex;
  /* padding: 95px 0 0px 0px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.gallery-List {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-around;

}

.gallery-List img {
  height: 200px;
  width: 300px;
  border-radius: 10px;
  object-fit: cover;

}

.gallery-Heading {
  font-weight: 600;
  font-size: 54px;
  margin-bottom: 2px;

}


/* Add these styles to your existing Gallery.css file */
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-preview-modal img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
  cursor: pointer;
}

.pagination {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.pagination button {
  min-width: 20px;
  border-radius: 5px;
  gap: 10px;
  border: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

/* Smartphones */
@media only screen and (max-width: 767px) {
  .gallery-MainContainer {
    padding: 100px 50px;
  }

  .gallery-List img {
    height: auto;
    width: 100%;
    border-radius: 10px;
  }

  .gallery-Heading {
    font-size: 30px;

  }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .gallery-MainContainer {
    padding: 100px 150px;

  }

  .gallery-Image {
    max-width: 450px;
    max-height: 350px;

  }

  .gallery-List img {
    height: auto;
    width: 100%;
    border-radius: 10px;
  }

}


@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .gallery-MainContainer {
    padding: 100px 250px;
  }

  .gallery-Image {
    max-width: 500px;
    max-height: 350px;

  }

  .gallery-List img {
    height: 200px;
    width: 295px;
    border-radius: 10px;
  }
}

/* Large monitors */
@media only screen and (min-width: 1800px) {
  .gallery-MainContainer {
    padding: 100px 300px;
  }

}