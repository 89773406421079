.main-commitee-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    align-items: center;
    gap: 80px;
    padding: 30px 100px;

}

.commitee-heading-container {
    font-size: 54px;
    font-weight: 700;
    padding-top: 80px;
}

.main-commitee-image-container {
    display: flex;
    padding-bottom: 50px;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
}

.commitee-image-container {
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    max-width: 360px;
    Height: 293px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.4s;
}

.commitee-image-container:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.02);
}

.commitee-image {
    Width: 314px;
    Height: 176px;
    border-radius: 10px;
    padding: 25px 26px 0px 25px;
}

.commitee-image-des {
    font-size: 15px;
    font-weight: 700;
    color: #7A7A7A;

}

.commitee-content div {

    padding: 10px 10vw;
    text-align: center;
}

.commitee-content div:first-child {
    font-size: 40px;
    font-weight: 600;
}

.commitee-member-container {
    padding: 15px;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    min-height:150px;
    box-shadow: 0px 0px 8px rgb(199, 199, 199);
    border-radius: 10px;
    /* border-bottom:1px solid #195776; */
    border-left: 2px solid #195776;

    border-top: 3px solid #195776;

}

.commitee-member-container:hover {
    transform: scale(1.05);
    transition: all .5s;

}

.commitee-member-container div {
    color: #195776;
    font-weight: 700;
    font-size: 18px;
}

.commitee-member-container p {
    color: #333333ac;
    font-weight: 400;
    font-size: 14px;
    background-color: #b0eafe20;
    border-radius: 2px;
    padding: 6px;
    margin: auto;
}

@media only screen and (max-width: 450px) {
    .commitee-heading-container {
        font-size: 30px;
        text-align: center;
    }

    .main-commitee-container {

        padding: 10px;
        gap: auto;
    }

    .commitee-content div:first-child {
        font-size: 5vw;
    }

    .commitee-content div {

        font-size: 3vw;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .commitee-heading-container {
        font-size: 40px;
        text-align: center;

    }

    .main-commitee-container {

        padding: 10px;

    }

    .commitee-content div:first-child {
        font-size: 4vw;
    }

    .commitee-content div {

        font-size: 3vw;
    }
}