.image-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-preview-modal img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    cursor: pointer;
  }