.submit {
  background-color: #3C8490;
  border: none;
  color: white;
  padding: 9px 10px;
  margin: 3px;
  border-radius: 10px;
  margin-right: 6px;
  /* margin-top: 60px; */
  font-size: 16px;
  font-weight: 700 !important;
  width: 200px;
  height: 38px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s
}

.submit:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}


.login {
  background-color: #3C8490;
  border: none;
  color: white;
  padding: 9px 10px;
  margin: 3px;
  border-radius: 10px;
  margin-right: 6px;
  font-size: 20px;
  font-weight: 700 !important;
  width: 346px;
  height:55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cancel-btn {
  background-color: #ffffff;
  border: none;
  color: #0173A7;
  padding: 9px 10px;
  margin: 5px;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600 !important;
  width: 200px;
  height: 38px;
  cursor: pointer;
  border-radius: 10px;
border: 1px solid var(--Primary-Color, #0173A7);
}
 /* Mobile styles */
 @media only screen and (max-width: 600px) {
  .submit,.cancel-btn {
 
    min-width: auto;
    width: fit-content;
}
 }