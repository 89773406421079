.title-card-contianer {
    margin: 20px 20px;
    width: auto;
    /* height: 157px; */
    border-radius: 20px;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    flex-wrap: wrap;
}

.title-card-title {
    color: var(--Accent-Color, #00517C);
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    display: flex;
}

.title-card-button-container {
    display: flex;
}

.title-card-button {
    display: flex;
    width: 183px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--Accent-Color, #00517C);
    align-items: center;
    justify-content: space-evenly;
    background: #FFF;
    cursor: pointer;
}

.title-card-button:hover {
    box-shadow: 0px 0pc 10px 0px #00517C;
}

.title-card-button-icon {
    width: 30px;
    height: 20px;
    font-size: 20px;
    color: var(--Accent-Color, #00517C);
}

.title-card-button-title {
    color: var(--Accent-Color, #00517C);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


  /* Mobile styles */
  @media only screen and (max-width: 600px) {
    .title-card-title {
    
        font-size: 20px;
    }
    .title-card-button-title {
      
        font-size: 12px;
    }
    .title-card-button-icon {
        width: 10px;
        height: 10px;
        font-size: 10px;
    }
    .title-card-button {
        width: 100px;
        height: 30px;
     
    }
    .title-card-contianer{
        padding: 20px 10px;
    }
    /* Add more mobile-specific styles as needed */
  }
  
  /* Tablet styles */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .title-card-title {
    
        font-size: 38px;
    }
  
    /* Add more tablet-specific styles as needed */
  }
  
  /* Large screen styles */
  @media only screen and (min-width: 1025px) {
    body {
      /* background-color: #cccccc; */
    }
  
    /* Add more large screen-specific styles as needed */
  }
  

